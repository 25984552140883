import React, { useState } from 'react';
import BatteryChecker from '../BatteryChecker/BatteryChecker';
import Home from '../Home/Home';
import LocationChecker from '../LocationChecker/LocationChecker';
import Notification from '../Notification/Notification';
import Settings from '../Settings/Settings';
import { useTrain } from '../TrainProvider';
import getTrainPositionData from '../utils/getTrainPositionData';
import { closeFullscreen, openFullscreen } from '../utils/openCloseFullscreen';
import './App.scss';
import SideContainer from './SideContainer/SideContainer';

function App() {
  const { isDriving, currentPosition } = useTrain();
  const [currentPage, setCurrentPage] = useState("home");

  const [inFullscreen, setInFullscreen] = useState(false);

  const positionData = getTrainPositionData(currentPosition);

  return (
    <div className={"App"} onDoubleClick={() => {
      if(inFullscreen) {
        closeFullscreen();
        setInFullscreen(false);
      }else{
        openFullscreen();
        setInFullscreen(true);
      }
    }}>
      <BatteryChecker />
      <LocationChecker />
      <Notification id="rotateScreenPlease" text={"Draai scherm"} icon={"far fa-sync-alt"} />

      {isDriving && positionData.id === 'einde' && (
        <Notification important text={"Eindstation"} icon={"far fa-home-lg-alt"} />
      )} 

      <div className="App-left">
        {currentPage === "home" && (
          <Home
            inFullscreen={inFullscreen}
            setFullScreen={() => {
              openFullscreen();
              setInFullscreen(true);
            }}
            openPage={page => setCurrentPage(page)}
            />
        )}

        {currentPage === "settings" && (
          <Settings
            openPage={page => setCurrentPage(page)}
            />
        )}
      </div>
      
      <SideContainer />
    </div>
  );
}

export default App;
