import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './js/App/App';
import * as serviceWorker from './serviceWorker';
import "./fontawesome/css/all.min.css"
import { TrainProvider } from './js/TrainProvider';
import trainData from './trainData';
import { Howl } from 'howler';
import StartSound from './sounds/start.mp3';
import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

setTimeout(() => {
  noSleep.enable();
}, 1500);

/** Converts numeric degrees to radians */
if (typeof(Number.prototype.toRad) === "undefined") {
  Number.prototype.toRad = function() {
    return this * Math.PI / 180;
  }
}

global.soundManager = {};

// Start sound
global.soundManager['start'] = new Howl({
  src: StartSound,
  preload: true
});

// Populate soundmanager
trainData.forEach(obj => {
  const sound = new Howl({
    src: obj.src,
    preload: true
  });

  global.soundManager[obj.id] = sound;
});


ReactDOM.render(
  <React.StrictMode>
    <TrainProvider>
      <App />
    </TrainProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Try 
if(window.screen  && window.screen.orientation && window.screen.orientation.lock) {
  try {
    window.screen.orientation.lock('landscape');
  } catch (err) {

  }
}

// Register PWA
serviceWorker.register();
