import React from 'react';
import "./homeButton.scss";

export default function HomeButton({ title, icon, onClick }) {
  return (
    <a
      href={"#"} 
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }} 
      className="big-home-button"
      >
      <span><i className={icon} /></span> {title}
    </a>
  )
}