import React from 'react';
import "./settingSwitch.scss";

export default function SettingSwitch({ title, enabled, onChange, disabled }) {
  return (
    <a href={"#"} className={"setting-switch"} onClick={e => {
      e.preventDefault();

      if(!disabled) {
        onChange();
      }
    }}>
      {title}

      {!disabled && (
        <i className={enabled === true ? 'switch-enabled far fa-toggle-on' : 'switch-disabled far fa-toggle-off'} />
      )}
    </a>
  );
}