import React, { useEffect } from 'react';
import Notification from '../Notification/Notification';
import { useTrain } from '../TrainProvider';
import trainData from '../../trainData';
import distance from '../utils/distance';

const hasGeolocation = () => 'geolocation' in window.navigator;

export default function LocationChecker() {
  const { isDriving, setIsDriving, lostGPS, setLostGPS, setCurrentPosition, passedPoints, hasPassedPoint, addPassedPoint, resetPassedPoints, hasGPSAllowed, disabledPositions } = useTrain();

  const updateTrainPosition = (location) => {
    setLostGPS(false);

    if(!isDriving || (location.coords.accuracy > 21 && location.coords.accuracy !== 150)) return;

    
    trainData.forEach((positionData, key) => {
      if(!hasPassedPoint(positionData.id)) {
        // Do not play the end sound on the beginning
        if((passedPoints.length === 0 && positionData.id === 'einde') || disabledPositions.indexOf(positionData.id) >= 0) return;

        // Check distance
        if(distance(location.coords.latitude, location.coords.longitude, positionData.lat, positionData.lon) < 15) {
          setCurrentPosition(key);
          addPassedPoint(positionData.id);

          if(positionData.id === 'einde') {
            setTimeout(() => {
              setIsDriving(false);
              resetPassedPoints();
            }, 25000);
          }

          if(global.soundManager[positionData.id]) {
            global.soundManager[positionData.id].play();
          }else{
            alert(`Cannot find file for '${positionData.id}'.`);
          }
        }
      }
    });
  };

  useEffect(() => {
    if(!hasGeolocation() || !hasGPSAllowed) return;

    // Request GPS permissions
    window.navigator.geolocation.getCurrentPosition(
      // Update location
      (location) => updateTrainPosition(location),

      // On error
      () => setLostGPS(true),
      {
        // Maximum age, 10 seconds
        maximumAge: 10000,

        // Timout, 5 seconds
        timeout: 5000,

        // Need high accuracy
        enableHighAccuracy: true
      });

    // Save watch ID
    const watchId = window.navigator.geolocation.watchPosition(
      updateTrainPosition, 
      () => setLostGPS(true),
      {
        // Maximum age, 10 seconds
        maximumAge: 10000,

        // Timout, 5 seconds
        timeout: 5000,

        // Need high accuracy
        enableHighAccuracy: true
      });

    // Clear watch
    return function cleanup() {
      window.navigator.geolocation.clearWatch(watchId);
    };
  });

  // If charging, and the battery level is higher then 95
  if(lostGPS) {
    return (
      <Notification important={true} text={"GPS signaal verloren!"} icon={"far fa-exclamation-triangle"} />
    )
  }

  return null;
}