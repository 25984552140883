import EndingSound from './sounds/einde.mp3';
import Weetjes from './sounds/weetjes.mp3';

// Attracties
import Binnenspeeltuin from './sounds/attracties/binnenspeeltuin.mp3';
import Waterspeeltuin from './sounds/attracties/tunnel-waterspeeltuin.mp3';
import Rondvaartboot from './sounds/attracties/rondvaartboot.mp3';

// Dieren
import Apen from './sounds/dieren/apen.mp3';
import BeverrattenOtters from './sounds/dieren/beverratten_otters.mp3';
import MarasNandoes from './sounds/dieren/maras_nandoes.mp3';
import Sneeuwuilen from './sounds/dieren/sneeuwuilen.mp3';
import Raven from './sounds/dieren/europese-raven.mp3';
import Everzwijnen from './sounds/dieren/everzwijnen.mp3';
import DierenEiland from './sounds/dieren/dieren-eiland.mp3';

const cords = [];

// Weetjes
cords.push({
  id: 'weetjes',
  name: 'Weetjes',
  src: Weetjes,
  lat: 51.956826,
  lon: 4.481093
});

// Binnenspeeltuin
cords.push({
  id: 'binnenspeeltuin',
  name: 'Binnenspeeltuin',
  src: Binnenspeeltuin,
  lat: 51.956052,
  lon: 4.480668
});

// // Verkeerstuin
// cords.push({
//   id: 'verkeerstuin',
//   name: 'Verkeerstuin',
//   src: Verkeerstuin,
//   lat: 51.955534,
//   lon: 4.481902
// });

// Waterspeeltuin
cords.push({
  id: 'waterspeeltuin',
  name: 'Waterspeeltuin',
  src: Waterspeeltuin,
  lat: 51.955141,
  lon: 4.482992
});

// Rondvaartboot
cords.push({
  id: 'rondvaartboot',
  name: 'Rondvaartboot',
  src: Rondvaartboot,
  lat: 51.955325,
  lon: 4.48462
});

// // Engelse tuin
// cords.push({
//   id: 'engelsetuin',
//   name: 'Engelse tuin',
//   src: EngelseTuin,
//   lat: 51.955366,
//   lon: 4.484718
// });

// Apen
cords.push({
  id: 'apen',
  name: 'Apen',
  src: Apen,
  lat: 51.955710,
  lon: 4.485669
});

// Sneeuwuilen
cords.push({
  id: 'sneeuwuilen',
  name: 'Sneeuwuilen',
  src: Sneeuwuilen,
  lat: 51.955880,
  lon: 4.486480
});

// Raven
cords.push({
  id: 'raven',
  name: 'Europese raven',
  src: Raven,
  lat: 51.956058,
  lon: 4.486324
});

// Dieren eiland
cords.push({
  id: 'dieren-eiland',
  name: 'Aailand',
  src: DierenEiland,
  lat: 51.956141,
  lon: 4.485422
});

// Everzwijnen
cords.push({
  id: 'everzwijnen',
  name: 'Everzwijnen',
  src: Everzwijnen,
  lat: 51.956926,
  lon: 4.484433
});

// Maras & Nandoes
cords.push({
  id: 'maras',
  name: 'Maras/Nandoes',
  src: MarasNandoes,
  lat: 51.956586,
  lon: 4.484081
});

// Beverratten & Otters
cords.push({
  id: 'beverratten',
  name: 'Beverratten/Otters',
  src: BeverrattenOtters,
  lat: 51.956948,
  lon: 4.482991
});

// Einde ronde
cords.push({
  id: 'einde',
  name: 'Einde',
  src: EndingSound,
  lat: 51.956942,
  lon: 4.482243,
  isEnding: true
});

export default cords;