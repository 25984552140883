import React, { useState } from "react";

const TrainContext = React.createContext();

const TrainProvider = props => {
  const [isDriving, setIsDriving] = useState(false);
  const [drivingSince, setDrivingSince] = useState(false);
  const [roundsCount, setRoundsCount] = useState(0);
  const [batteryLow, setBatteryLow] = useState(false);
  const [lostGPS, setLostGPS] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [gpsAccuracy, setGpsAccuracy] = useState(0);
  const [passedPoints, setPassedPoints] = useState([]);
  const [disabledPositions, setDisabledPositions] = useState(localStorage.disabled_positions ? localStorage.disabled_positions : []);
  const [hasGPSAllowed, setHasGPSAllowed] = useState(localStorage.allowed_gps ? localStorage.allowed_gps : false);

  // Add passed point
  const addPassedPoint = (key) => {
    const points = passedPoints;
    points.push(key);
    setPassedPoints(points);
  };

  // Check point has been passed
  const hasPassedPoint = (key) => {
    return passedPoints.indexOf(key) >= 0;
  };

  // Check point has been passed
  const resetPassedPoints = () => {
    setPassedPoints([]);
    setCurrentPosition(null);
  };

  return (
    <TrainContext.Provider
      value={{
        isDriving,
        setIsDriving,

        drivingSince,
        setDrivingSince,

        // Rounds
        roundsCount,
        setRoundsCount,

        // Battery
        batteryLow,
        setBatteryLow,

        // GPS
        gpsAccuracy,
        setGpsAccuracy,

        lostGPS,
        setLostGPS,

        // Current point
        currentPosition,
        setCurrentPosition,

        // Disabled positions
        disabledPositions,
        setDisabledPositions: (obj) => {
          localStorage.disabled_positions = obj;

          setDisabledPositions(obj)
        },

        // Passed points
        passedPoints,
        addPassedPoint,
        hasPassedPoint,
        resetPassedPoints,

        hasGPSAllowed,
        setHasGPSAllowed
      }}
      {...props}
    />
  );

};

// useAgency
const useTrain = () => {
    const context = React.useContext(TrainContext);
  
    // Using outside context
    if (context === undefined) {
      throw new Error(`useTrain must be used within a TrainProvider`);
    }
  
    return context;
  };

export { TrainProvider, useTrain };
