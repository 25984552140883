import React from 'react';
import HomeButton from '../HomeButton/HomeButton';
import { useTrain } from '../TrainProvider';

export default function Home({ inFullscreen, setFullScreen, openPage }) {
  const { isDriving, setIsDriving, resetPassedPoints, hasGPSAllowed, setHasGPSAllowed, setDrivingSince } = useTrain();

  return (
    <>
      {hasGPSAllowed && (
        <HomeButton
          icon={isDriving ? 'far fa-traffic-light-stop' : 'far fa-play-circle'} 
          title={isDriving ? 'Reset' : 'Start'}
          onClick={() => {
            if(isDriving) {
              resetPassedPoints();
              setIsDriving(false);

              Object.keys(global.soundManager).forEach(key => {
                global.soundManager[key].stop();
              })
            }else {
              setIsDriving(true);

              const driveTimeNow = new Date();
              setDrivingSince(driveTimeNow.toString());

              global.soundManager['start'].play();
            }
          }} 
          />
      )}

      {!hasGPSAllowed && (
        <HomeButton 
          icon={'far fa-map-marker-check'} 
          title={'Vraag GPS toestemming'}
          onClick={() => {
            window.navigator.geolocation.getCurrentPosition(
              // Update location
              () => {
                setHasGPSAllowed(true);
                localStorage.allowed_gps = true;
              },
        
              // On error
              (e) => alert(e.message),
              {
                // Maximum age, 10 seconds
                maximumAge: 10000,
        
                // Timout, 5 seconds
                timeout: 5000,
        
                // Need high accuracy
                enableHighAccuracy: true
              });
          }} 
          />
      )}

      {!window.matchMedia("(display-mode: standalone)").matches && window.deferedPrompt && (
        <HomeButton 
          icon={'far fa-download'} 
          title={'Installeren'}
          onClick={() => {
            if (window.deferedPrompt) {
              window.deferedPrompt.prompt();
            }
          }} 
          />
      )}

      {!inFullscreen && (
        <HomeButton 
          icon={'far fa-expand-wide'} 
          title={'Volledig scherm'}
          onClick={setFullScreen} 
          />
      )}

      <HomeButton 
        icon={'far fa-sliders-v-square'} 
        title={'Instellingen'}
        onClick={() => openPage('settings')} 
        />
    </>
  )
}