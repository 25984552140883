import React from 'react';
import HomeButton from '../HomeButton/HomeButton';
import SettingSwitch from '../SettingSwitch/SettingSwitch';
import trainData from '../../trainData';
import { useTrain } from '../TrainProvider';

export default function Settings({ openPage }) {
  const { disabledPositions, setDisabledPositions } = useTrain();

  return (
    <>
      <HomeButton
        icon={'far fa-arrow-left'} 
        title={'Terug'}
        onClick={() => openPage('home')} 
        />

      {trainData.map(obj => {
        return (
          <SettingSwitch
            key={obj.id}
            title={obj.name} 
            enabled={disabledPositions.indexOf(obj.id) === -1}
            disabled={obj.id === 'einde'}
            onChange={() => {
              const updatedArray = [...disabledPositions];
              const foundPosition = disabledPositions.indexOf(obj.id);

              if(foundPosition >= 0) {
                updatedArray.splice(foundPosition, 1);
              }else{
                updatedArray.push(obj.id);
              }

              setDisabledPositions(updatedArray);
            }}
            />
        )
      })}
    </>
  );
}