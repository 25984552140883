import React, { useEffect, useState } from 'react';
import { useTrain } from '../../TrainProvider';
import countFromDate from '../../utils/countFromDate';
import getTrainPositionData from '../../utils/getTrainPositionData';
import './sideContainer.scss';

function SideContainer() {
  const { roundsCount, isDriving, batteryLow, currentPosition, drivingSince } = useTrain();

  const positionData = getTrainPositionData(currentPosition);
  
  const [driveTime, setDriveTime] = useState('00:00');

  useEffect(() => {
    const driverTime = setInterval(() => {
      if(!isDriving) {
        setDriveTime('00:00');
        return;
      }

      setDriveTime(countFromDate(drivingSince));
    }, 1000)

    return function cleanup() {
      clearTimeout(driverTime);
    }
  });

  return (
    <div className="App-right">
      <div className={"data-container"}>
        <div id="gps-accuracy"></div>

        <div className="app-status">
          <i className={`far fa-${batteryLow ? 'battery-empty blink-only' : 'badge-check'}`}></i> Status
        </div>

        <div className="stat-data stat-data-right">Ronde: <b>{roundsCount}</b></div>

        {isDriving && (
          <>
            <div className="stat-data stat-data-position">Tijd: <b>{driveTime}</b></div>
            <div className="stat-data stat-data-position">Huidige plek: <b>{positionData.name || "Geen"}</b></div>
          </>
        )}
      </div>
    </div>
  );
}

export default SideContainer;
