import React, { useEffect, useState } from 'react';
import Notification from '../Notification/Notification';
import { useTrain } from '../TrainProvider';

const isBatteryAPISupported = () => {
  return navigator.getBattery || navigator.battery || navigator.mozBattery;
}

function getBattery() {
  if ('getBattery' in navigator) {
    return navigator.getBattery();
  } else {
    return Promise.resolve(navigator.battery);
  }
}

export default function BatteryChecker() {
  const { setBatteryLow } = useTrain();

  const [isCharging, setIsCharging] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState(null);

  useEffect(() => {
    if(!isBatteryAPISupported()) return;

    // Update level change
    function updateBatteryLevelChange() {
      setBatteryLevel(this.level);

      // Save in store
      setBatteryLow(this.level <= 0.15 && !this.charging);
    };

    // Update charging change
    function updateBatteryChargingChange(e) {
      setIsCharging(this.charging);
    };

    getBattery().then(function(battery) {
      
      setBatteryLevel(battery.level);
      setIsCharging(battery.charging);

      // Save in store
      setBatteryLow(battery.level <= 0.15 && !battery.charging);

      battery.addEventListener('chargingchange', updateBatteryChargingChange);
      battery.addEventListener('levelchange', updateBatteryLevelChange);
    });

    return function cleanup() {
      getBattery().then(function(battery) {
        battery.removeEventListener('chargingchange', updateBatteryChargingChange);
        battery.removeEventListener('levelchange', updateBatteryLevelChange);
      });
    };
  });

  // Do not show if not initiated
  if(batteryLevel === null) return null;

  // If charging, and the battery level is higher then 95
  if(isCharging && batteryLevel >= 0.95) {
    return (
      <Notification text={"BATTERIJ IS OPGELADEN"} icon={"far fa-battery-full"} />
    )
  }

  // If the battery level is low and the phone is not charging
  if(batteryLevel <= 0.15 && !isCharging) {
    return (
      <Notification important={true} text={"Batterij bijna leeg"} icon={"far fa-battery-empty"} />
    )
  }

  return null;
}